<template>
  <div class="auth-page-wrapper pt-5">
    <!-- auth page bg -->
    <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
      <div class="bg-overlay"></div>

      <div class="shape">
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 1440 120">
          <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
        </svg>
      </div>
      <canvas class="particles-js-canvas-el" width="1518" height="380" style="width: 100%; height: 100%"></canvas>
    </div>

    <!-- auth page content -->
    <div class="auth-page-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center mt-sm-5 mb-4 text-white-50">
              <div>
                <a href="index.html" class="d-inline-block auth-logo">
                  <img src="/assets/images/logo-light.png" alt="" width="200" />
                </a>
              </div>
              <p class="mt-3 fs-15 fw-medium">
                Daftar Saham <span class="text-capitalize">Inalife</span>
              </p>
            </div>
          </div>
        </div>
        <!-- end row -->

        <div class="row justify-content-center">
          <div class="col-md-9">
            <div class="card mt-0">
              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <h5 class="text-primary">Daftar akun !</h5>
                  <p class="text-muted">Silahkan mendaftar dan jadilah owner unit usaha inalife indonesia.</p>
                </div>
                <FormShimmer :count="10" v-if="loadPeriod" />
                <div class="p-2 mt-4" v-else>
                  <div class="text-center" v-if="!share_period">
                    <div class="mb-1">
                      <lord-icon src="https://cdn.lordicon.com/tyounuzx.json" trigger="loop"
                        colors="primary:#0ab39c,secondary:#405189" style="width:100px;height:100px">
                      </lord-icon>
                    </div>
                    <h5>Pembelian Saham Sedang Tidak Tersedia!</h5>
                  </div>
                  <form @submit.prevent="submit" v-else>

                    <div class="mb-3">
                      <label class="form-label">Email</label>
                      <input v-model="register.email" type="text" class="form-control" placeholder="Masukkan email"
                        required />
                    </div>

                    <div class="mb-3">
                      <label class="form-label" for="password-input">Password</label>
                      <div class="position-relative auth-pass-inputgroup mb-3">
                        <input v-model="register.password" :type="type.password" class="form-control pe-5"
                          placeholder="Enter password" required />
                        <button @click="addonPassword('password')"
                          class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                          type="button">
                          <i class="ri-eye-fill align-middle"></i>
                        </button>
                      </div>
                    </div>

                    <div class="mb-4">
                      <label class="form-label" for="password-input">Konfirmasi Password</label>
                      <div class="position-relative auth-pass-inputgroup mb-3">
                        <input v-model="register.password_confirmation" :type="type.password_confirmation"
                          class="form-control pe-5" placeholder="Enter password" required />
                        <button @click="addonPassword('password_confirmation')"
                          class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                          type="button">
                          <i class="ri-eye-fill align-middle"></i>
                        </button>
                      </div>
                    </div>



                    <h5 class="text-center text-primary">Biodata</h5>

                    <div class="mb-3">
                      <label class="form-label">Name</label>
                      <input v-model="register.name" type="text" class="form-control" placeholder="Masukkan Nama"
                        required />
                    </div>

                    <div class="mb-3">
                      <label class="form-label">Alamat</label>
                      <textarea v-model="register.address" type="text" class="form-control"
                        placeholder="Masukkan Alamat" required></textarea>
                    </div>

                    <div class="mb-3">
                      <label class="form-label">Nomor ID (KTP/Passport)</label>
                      <input v-model="register.nik" type="number" class="form-control"
                        placeholder="Masukkan Nomor ID (KTP/Passwort)" required />
                    </div>

                    <div class="mb-3">
                      <label class="form-label">Foto KTP/Passport</label>
                      <input type="file" class="form-control" accept="image/png, image/jpeg, image/jpg"
                        @change="ktpChange($event)" required />
                      <small class="text-muted">Max. 10MB (Jika melebihi tidak akan terupload dan proses verifikasi akan
                        lebih lama)</small>
                    </div>

                    <div class="mb-4">
                      <label class="form-label">Nomor Telepon (WA)</label>
                      <input v-model="register.phone_number" type="number" class="form-control"
                        placeholder="Masukkan Nomot Telepon (WA)" required />
                    </div>




                    <h5 class="text-center text-primary mt-2">Akun Bank</h5>

                    <div class="mb-3">
                      <label class="form-label">Nama Bank</label>
                      <input v-model="register.bank_name" type="text" class="form-control"
                        placeholder="Masukkan Nama Bank Contoh:BRI,BNI" required />
                    </div>

                    <div class="mb-3">
                      <label class="form-label">Nama Akun Bank</label>
                      <input v-model="register.bank_account_name" type="text" class="form-control"
                        placeholder="Masukkan Nama Akun Bank Contoh:Monaroh" required />
                    </div>

                    <div class="mb-4">
                      <label class="form-label">Nomor Akun Bank</label>
                      <input v-model="register.bank_account_number" type="number" class="form-control"
                        placeholder="Masukkan Nomor Akun Bank Contoh:111222333" required />
                    </div>




                    <h5 class="text-center text-primary">Biodata Ahli Waris</h5>

                    <div class="mb-3">
                      <label class="form-label">Nama Ahli Waris</label>
                      <input v-model="register.heir_name" type="text" class="form-control"
                        placeholder="Masukkan Nama Ahli Waris" required />
                    </div>

                    <div class="mb-3">
                      <label class="form-label">Nomor ID Ahli Waris (KTP/Passport)</label>
                      <input v-model="register.heir_nik" type="number" class="form-control"
                        placeholder="Masukkan Nomor ID Ahli Waris (KTP/Passport)" required />
                    </div>

                    <div class="mb-3">
                      <label class="form-label">Nomor Telopon Ahli Waris (WA)</label>
                      <input v-model="register.heir_phone_number" type="number" class="form-control"
                        placeholder="Masukkan Nomor Telepon Ahli Waris" required />
                    </div>

                    <div class="mb-1">
                      <h5 class="text-center text-primary">Detail Saham</h5>
                    </div>

                    <div v-if="share_period">
                      <div class="alert alert-primary text-center mb-3" role="alert">
                        <p>1 slot saham Inalife ( berisi 10 lembar saham ) <b>{{  share_period['name']  }}</b> seharga
                          <b>{{  toIdr(share_period['price'])  }}-</b>
                        </p>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="alert alert-success text-center mb-3" role="alert">
                            <p>Total Harga</p>
                            <h3><b>{{  toIdr(price_total)  }}</b></h3>
                            <p>Lakukan pembayaran ke nomor reking
                              <strong>646401032252538</strong> atas nama
                              <strong>INALIFE
                                INDONESIA</strong>
                            </p>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-3">
                            <label class="form-label">Jumlah Slot</label>
                            <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected mb-3">
                              <span class="input-group-btn input-group-prepend">
                                <button class="btn btn-primary bootstrap-touchspin-down" type="button"
                                  @click.prevent="minSLot()">-</button>
                              </span>
                              <input type="number" v-model="register.number_of_slot" class="form-control">
                              <span class="input-group-btn input-group-append">
                                <button class="btn btn-primary bootstrap-touchspin-up" type="button"
                                  @click.prevent="addSLot()">+</button>
                              </span>
                            </div>

                            <div class="mb-3">
                              <label class="form-label">Bukti Transfer</label>
                              <input type="file" class="form-control" accept="image/png, image/jpeg, image/jpg"
                                @change="transferChange($event)" required />
                              <small class="text-muted">Max. 10MB (Jika melebihi tidak akan terupload dan proses
                                verifikasi
                                akan
                                lebih lama)</small>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>

                    <div class="mt-4">
                      <button :disabled="load || !share_period" class="btn btn-success w-100" type="submit">
                        <i class="bx font-size-16 align-middle me-2" :class="{
                          'bx-loader bx-spin': load,
                          'bx-send': !load,
                        }"></i>
                        Mendaftar
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div class="mt-4 text-center">
              <p class="mb-0">
                Sudah memiliki akun ?
                <router-link :to="{ name: 'login' }" class="fw-semibold text-primary text-decoration-underline">
                  Login disini
                </router-link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <p class="mb-0 text-muted">&copy;2022 - {{  year  }} {{  title  }}</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import axios from 'axios';
import { endpoint } from '../../host';
import errorService from '../../services/error-service';
import httpService from '../../services/http-service';
import globalService from '../../services/global-service';
import moment from 'moment';
import FormShimmer from '../../components/shimmer/FormShimmer.vue';

export default {
  created() {
    var scripts = [
      "assets/libs/particles.js/particles.js",
      "assets/js/pages/particles.app.js",
    ];
    scripts.forEach((script) => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    });
    this.getActiveSharePeriod();
  },
  data() {
    return {
      loadPeriod: false,
      title: process.env.VUE_APP_TITLE,
      password_type: "password",
      share_period: null,
      load: false,
      year: moment().year(),
      price_total: 0,
      register: {
        email: null,
        password: null,
        password_confirmation: null,
        name: null,
        nik: null,
        phone_number: null,
        address: null,
        bank_name: null,
        bank_account_number: null,
        bank_account_name: null,
        heir_nik: null,
        heir_name: null,
        heir_phone_number: null,
        share_period_id: null,
        number_of_slot: 1,
        ktp: null,
        evidence_of_transfer: null,
      },
      type: {
        password: "password",
        password_confirmation: "password",
      },
    };
  },
  methods: {
    async submit() {
      try {
        this.load = true;
        let formData = new FormData();
        for (const key in this.register) {
          formData.append(key, this.register[key] ?? "");
        }
        let r = await axios.post(endpoint.register + "/register", formData);
        this.load = false;
        if (r.status == 200) {
          localStorage.setItem("user", JSON.stringify(r.data.data.user));
          localStorage.setItem("token", r.data.data.token);
          localStorage.setItem("permissions", JSON.stringify(r.data.data.permissions));
          localStorage.setItem("roles", JSON.stringify(r.data.data.roles));
          this.$router.push({
            name: "user.home",
            params: {
              reload: true,
              complate_profile: false,
            },
          });
        }
      }
      catch (error) {
        this.load = false;
        errorService.displayError(error);
      }
    },
    async getActiveSharePeriod() {
      this.loadPeriod = true;
      let res = await httpService.get(httpService.generateEndpointQuery(endpoint["share.period"], {
        is_active: "1",
      }));
      this.loadPeriod = false;
      if (res.data.data.length > 0) {
        this.share_period = res.data.data[0];
        this.register.share_period_id = this.share_period["id"];
      }
    },
    minSLot() {
      if (this.register.number_of_slot > 1) {
        this.register.number_of_slot--;
      }
      this.price_total = this.register.number_of_slot * this.share_period["price"];
    },
    addSLot() {
      if (this.register.number_of_slot < 100) {
        this.register.number_of_slot++;
      }
      this.price_total = this.register.number_of_slot * this.share_period["price"];
    },
    ktpChange(event) {
      this.register.ktp = event.target.files[0];
    },
    transferChange(event) {
      this.register.evidence_of_transfer = event.target.files[0];
    },
    addonPassword(a) {
      if (a == "password") {
        this.type.password == "password"
          ? (this.type.password = "text")
          : (this.type.password = "password");
      }
      else if (a == "password_confirmation") {
        this.type.password_confirmation == "password"
          ? (this.type.password_confirmation = "text")
          : (this.type.password_confirmation = "password");
      }
    },
    toIdr(a) {
      return globalService.toIdr(a);
    }
  },
  components: { FormShimmer }
};
</script>

<style>
</style>